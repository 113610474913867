import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgAboutHero from "../../assets/nightshift/about-cloud-img-1100px.png";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content = () => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col lg="6">
            <Title variant="hero">
              Our Story.
            </Title>
          </Col>
          <Col lg="6" className="pl-lg-5">
            <Text>
              After witnessing many years of friction, lost time, and substantial shortage of talent, we decided enough was enough. 
              In an effort to cost-optimize a highly regulated project for a valued customer, we successfully pitched the idea of 
              infrastructure-as-code so good we deleted it all every night, and successfully rebuilt and relied on it every morning for months.
            </Text>
            <br />
            <Text>
              Some time later, disaster struck. An honest mistake by an outside party resulted in the complete destruction of the production environment, 
              and everything in it. As panic began to grip management, we simply smiled, made the next set of adjustments we intended to introduce,
               and redeployed all services, configuration, security, enterprise identity management, and integration
                within an hour, spanning multiple vendors and services, all from a single config file.
            </Text>
            <br />
            <Text>
              On the strength of that success, NightShift was born, and is committed to combining the ‘as-code’ philosophy and 
              benefits it provides to digital infrastructure, with using the time saved on infrastructure management to focus on application architecture,
               fundamentally unleashing the full promise of cloud-native container solutions.
            </Text>
            <br />
            <Text>
              Our team is deeply versed in both operations and application development, and our culture is engineering centric. 
              We are self-hosted, self-developed, self-branded, and self-funded.
            </Text>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="12" sm="12" className="mb-4">
            <ContentImg>
              <img src={imgAboutHero} alt="The right tools" className="img-fluid" />
            </ContentImg>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content;

